import Panel from 'components/content/panel/panel.vue';
import Btn from 'components/base/button/button.vue';
export default {
  components: {
    Panel: Panel,
    Btn: Btn
  },
  props: {
    table: {
      type: Array,
      required: true
    }
  }
};