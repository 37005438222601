//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import IconBtn from 'components/content/icon-btn/icon-btn.vue';
export default {
  components: {
    IconBtn: IconBtn
  },
  methods: {
    print: function print() {
      window.print();
    }
  }
};