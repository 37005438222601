//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SvgIcon from 'components/base/svg/svg.vue';
export default {
  components: {
    SvgIcon: SvgIcon
  },
  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  }
};