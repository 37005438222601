import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import App from 'components/app.vue';
import Index from 'pages/index.vue';
import Pellets from 'pages/pellets.vue';
import Maintenance from 'pages/maintenance.vue';
import 'js/firebase-init';
import { initState } from 'js/modules/store';
Vue.use(Vuelidate);
Vue.use(VueRouter);
initState();
var router = new VueRouter({
  mode: 'history',
  routes: [{
    name: 'meter-reading',
    path: '/meter-reading',
    meta: {
      title: 'Gaszählerablesung | Energie 360°'
    },
    component: Index
  }, {
    name: 'pellets',
    path: '/pellets',
    meta: {
      title: 'Pellets | Energie 360°'
    },
    component: Pellets
  }, {
    path: '*',
    meta: {
      title: 'Systemwechsel | Energie 360°'
    },
    component: Maintenance
  }],
  scrollBehavior: function scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});
router.beforeEach(function (to, from, next) {
  console.log('router.beforeEach:', to, from, next);

  var getQuery = function getQuery(url) {
    return url.match(/\?.+/g)[0];
  };

  if (to.fullPath.startsWith('/sap/bc/ui5_ui5/sap/z360_customer')) {
    if (to.query.action === 'wood_pellet') {
      next({
        path: '/pellets' + getQuery(to.fullPath)
      });
    } else if (to.query.action === 'MeterReading') {
      next({
        path: '/meter-reading' + getQuery(to.fullPath)
      });
    } else {
      next();
    }
  } else if (to.fullPath.startsWith('/de/property/meterReading/')) {
    next({
      path: '/meter-reading' + getQuery(to.fullPath)
    });
  } else {
    next();
  }
});
/* eslint-disable */

new Vue({
  render: function render(h) {
    return h(App);
  },
  router: router,
  el: '#app'
});
/* eslint-enable */