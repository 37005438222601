import env from 'js/env';
import { global } from 'js/util/global';
import { store } from 'js/modules/store';
console.log(env);
var firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  databaseURL: env.FIREBASE_DATABASE_URL,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGIN_SENDER_ID,
  appId: env.FIREBASE_APP_ID
};
var fb = window.firebase;
fb.initializeApp(firebaseConfig); // Authenticate

fb.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log('signed in!');
    setTimeout(function () {
      store.setSignedIn(true);
    }, 100);
  } else {
    // No user is signed in.
    console.log('NOT signed in!'); // Sign in now

    fb.auth().signInWithEmailAndPassword(env.APP_USER_EMAIL, env.APP_USER_PASSWORD);
  }
});
global.db = fb.firestore();