import { global } from 'js/util/global';
var forcedDelay = 1000;
/**
 * Write a document to a firestore collection
 *
 * @param {string} collection - Name of collection an a firestore database (root)
 * @param {string} docId
 * @param {Object} documentObject - Data of document
 * @returns {Promise}
 */

var writeDocument = function writeDocument(collection, documentObject) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      global.db.collection(collection).add(documentObject).then(function (docRef) {
        resolve(docRef.id);
      }).catch(function (error) {
        reject(error);
      });
    }, forcedDelay);
  });
};
/**
 * Get a document from a firestore collection
 *
 * @param {string} collection
 * @param {string} docId
 * @returns {Promise}
 */


var readDocument = function readDocument(collection, docId) {
  return new Promise(function (resolve, reject) {
    global.db.collection(collection).doc(docId).get().then(function (doc) {
      if (doc.exists) {
        resolve(doc.data());
      } else {
        resolve(false);
      }
    }).catch(function (error) {
      reject(error);
    });
  });
};

export { writeDocument, readDocument };