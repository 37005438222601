import InputField from 'components/base/input/input.vue';
import Btn from 'components/base/button/button.vue';
import Radio from 'components/base/radio/radio.vue';
import Checkbox from 'components/base/checkbox/checkbox.vue';
import Confirmation from 'components/content/confirmation/confirmation.vue';
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators';
import errorMessages from 'js/util/error-messages';
import { readDocument, writeDocument } from 'js/modules/db-handler';
import config from 'js/config';
import { store } from 'js/modules/store';
import Popper from 'vue-popperjs';
import IconBtn from 'components/content/icon-btn/icon-btn.vue';

var pad = function pad(nr) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return nr.toString().padStart(length, '0');
};

export default {
  components: {
    InputField: InputField,
    Btn: Btn,
    Radio: Radio,
    Checkbox: Checkbox,
    Confirmation: Confirmation,
    Popper: Popper,
    IconBtn: IconBtn
  },
  data: function data() {
    return {
      sharedState: store.state,
      counterNrError: '',
      internetCodeError: '',
      clientEmailError: '',
      readingDate: "".concat(pad(new Date().getDate()), ".").concat(pad(new Date().getMonth() + 1), ".").concat(new Date().getFullYear()),
      counterReading: '',
      counterReadingError: '',
      clientNr: '',
      clientNrError: '',
      streetAndNr: '',
      streetAndNrError: '',
      zipCode: '',
      zipCodeError: '',
      city: '',
      cityError: '',
      interimInvoice: false,
      submitting: false,
      result: []
    };
  },
  computed: {
    counterNr: {
      get: function get() {
        return this.sharedState.counterNr;
      },
      set: function set(counterNr) {
        store.setCounterNr(counterNr);
      }
    },
    internetCode: {
      get: function get() {
        return this.sharedState.internetCode;
      },
      set: function set(internetCode) {
        store.setInternetCode(internetCode);
      }
    },
    clientEmail: {
      get: function get() {
        return this.sharedState.clientEmail;
      },
      set: function set(clientEmail) {
        store.setClientEmail(clientEmail);
      }
    },
    formType: {
      get: function get() {
        return this.sharedState.formType;
      },
      set: function set(t) {
        store.updateFormType(t);
      }
    },
    verificationType: {
      get: function get() {
        return this.sharedState.verificationType;
      },
      set: function set(vt) {
        store.updateVerificationType(vt);
      }
    },
    dataSubmitted: {
      get: function get() {
        return this.sharedState.dataSubmitted;
      }
    }
  },
  methods: {
    sanitize: function sanitize(modelName, options) {
      for (var i = 0; i < options.length; i++) {
        switch (options[i]) {
          case 'trimLeadingZeros':
            this[modelName] = this[modelName].replace(/^[0]+/g, '');
            break;

          case 'removeWhitespaces':
            this[modelName] = this[modelName].replace(/\s/g, '');
            break;

          case 'noDecimals':
            this[modelName] = this[modelName].replace(/\..+$/g, '');
        }
      }

      return true;
    },
    validate: function validate(modelName) {
      this.$v.$touch();
      var validationModel = this.$v[modelName];
      var errModelName = modelName + 'Error';

      if (validationModel.$anyError) {
        for (var p in validationModel.$params) {
          if (Object.prototype.hasOwnProperty.call(validationModel.$params, p)) {
            if (!validationModel[p]) {
              switch (p) {
                case 'required':
                  this[errModelName] = errorMessages[p]();
                  break;

                case 'email':
                  this[errModelName] = errorMessages[p]();
                  break;

                case 'minLength':
                  this[errModelName] = errorMessages[p](validationModel.$params[p].min);
                  break;

                case 'maxLength':
                  this[errModelName] = errorMessages[p](validationModel.$params[p].max);
                  break;

                case 'numeric':
                  this[errModelName] = errorMessages[p]();
                  break;

                default:
                  this[errModelName] = errorMessages.error();
              }
            }
          }
        }
      } else {
        this[errModelName] = '';
      }
    },
    submit: function submit() {
      var _this = this;

      this.$v.$touch();
      console.log('form is valid: ', !this.$v.$invalid);
      console.log('form has errors: ', this.$v.$error); // Show or clear errors

      for (var p in this.$v.$params) {
        if (Object.prototype.hasOwnProperty.call(this.$v.$params, p)) {
          this.validate(p);
        }
      }

      if (!this.$v.$anyError) {
        console.log('Form is valid!'); // Verify data

        if (this.formType === 'withCard') {
          readDocument(config.clientDataCollection, "".concat(this.counterNr, "-").concat(this.internetCode)).then(function (doc) {
            if (doc) {
              _this.counterNrError = '';
              _this.internetCodeError = '';

              _this.postData();
            } else {
              // Show error messages
              _this.counterNrError = errorMessages.counterNrInvalid();
              _this.internetCodeError = errorMessages.internetCodeInvalid();
            }
          });
        } else {
          // We can't verify anything here.
          // Just send datat to backend
          this.postData();
        }
      }
    },
    postData: function postData() {
      var _this2 = this;

      this.submitting = true; // Check honeypot field

      if (this.$refs.hpField.value !== '') {
        // We have a robot!
        // Don't send anything.
        // But we still show the confirmation page.
        store.setDataSubmitted(true);
        this.submitting = false;
        this.result = this.getResultTable();
        return;
      }

      writeDocument(config.readingCollection, {
        zaehlernummer: this.counterNr,
        internetCode: this.internetCode,
        kundennummer: this.formType === 'withoutCard' && this.verificationType === 'clientNr' ? this.clientNr : '',
        strasse_hausnummer: this.formType === 'withoutCard' && this.verificationType === 'objectAddress' ? this.streetAndNr : '',
        plz: this.formType === 'withoutCard' && this.verificationType === 'objectAddress' ? this.zipCode : '',
        ort: this.formType === 'withoutCard' && this.verificationType === 'objectAddress' ? this.city : '',
        mit_zwischenrechnung: this.formType === 'withoutCard' ? this.interimInvoice ? 'Ja' : 'Nein' : '',
        email: this.clientEmail,
        ablesedatum: this.readingDate,
        zaehlerstand: this.counterReading
      }).then(function (docRef) {
        console.log(docRef);
        store.setDataSubmitted(true);
        _this2.submitting = false;
        _this2.result = _this2.getResultTable();
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 40);
      });
    },
    getResultTable: function getResultTable() {
      var result = [];
      result.push(['Zählernummer', this.counterNr]);

      if (this.formType === 'withCard') {
        result.push(['Internetcode', this.internetCode]);
      }

      if (this.formType === 'withoutCard') {
        if (this.verificationType === 'clientNr') {
          result.push(['Kundennummer', this.clientNr]);
        }

        if (this.verificationType === 'objectAddress') {
          result.push(['Strasse und Hausnummer', this.streetAndNr]);
          result.push(['PLZ, Ort', "".concat(this.zipCode, ", ").concat(this.city)]);
        }

        result.push(['Mit Zwischenrechnung', this.interimInvoice ? 'Ja' : 'Nein']);
      }

      result.push(['E-mail', this.clientEmail]);
      result.push(['Ablesedatum', this.readingDate]);
      result.push(['Zählerstand', "".concat(this.counterReading, " m\xB3")]);
      return result;
    },
    onNewReading: function onNewReading() {
      this.$v.$reset(); // Reset form

      store.reset();
      this.counterNr = '';
      this.counterNrError = '';
      this.internetCode = '';
      this.internetCodeError = '';
      this.clientEmail = '';
      this.clientEmailError = '';
      this.readingDate = "".concat(pad(new Date().getDate()), ".").concat(pad(new Date().getMonth() + 1), ".").concat(new Date().getFullYear());
      this.counterReading = '';
      this.counterReadingError = '';
      this.clientNr = '';
      this.clientNrError = '';
      this.streetAndNr = '';
      this.streetAndNrError = '';
      this.zipCode = '';
      this.zipCodeError = '';
      this.city = '';
      this.cityError = '';
      this.interimInvoice = false;
      this.submitting = false;
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 40);
    }
  },
  validations: {
    counterNr: {
      required: required,
      maxLength: maxLength(18),
      numeric: numeric
    },
    internetCode: {
      required: requiredIf(function () {
        return this.formType === 'withCard';
      }),
      minLength: minLength(8),
      maxLength: maxLength(8)
    },
    clientEmail: {
      required: required,
      email: email
    },
    counterReading: {
      required: required,
      numeric: numeric
    },
    clientNr: {
      required: requiredIf(function () {
        return this.formType === 'withoutCard' && this.verificationType === 'clientNr';
      }),
      maxLength: maxLength(18),
      numeric: numeric
    },
    streetAndNr: {
      required: requiredIf(function () {
        return this.formType === 'withoutCard' && this.verificationType === 'objectAddress';
      })
    },
    zipCode: {
      required: requiredIf(function () {
        return this.formType === 'withoutCard' && this.verificationType === 'objectAddress';
      }),
      numeric: numeric,
      minLength: minLength(4)
    },
    city: {
      required: requiredIf(function () {
        return this.formType === 'withoutCard' && this.verificationType === 'objectAddress';
      })
    }
  }
};