//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Header from 'components/global/header/header.vue';
import Footer from 'components/global/footer/footer.vue';
import { store } from 'js/modules/store';
export default {
  components: {
    Header: Header,
    Footer: Footer
  },
  created: function created() {
    console.log('App created: ', this.$route);
    document.title = this.$route.meta.title;
    this.readQueryStr();
  },
  watch: {
    $route: function $route(to, from) {
      console.log(to, from);
      document.title = to.meta.title;
    }
  },
  methods: {
    readQueryStr: function readQueryStr() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'counter')) {
        store.setCounterNr(this.$route.query.counter);
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'code')) {
        store.setInternetCode(this.$route.query.code);
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'email')) {
        store.setClientEmail(this.$route.query.email);
      }
    }
  }
};