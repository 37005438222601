import { set, get } from 'js/util/session-storage';
import { merge } from 'js/util/merge';
var storageKey = 'e360-app-state'; // Don't store these properties to sessionStorage

var exceptions = ['signedIn'];
/**
 * Save state to sessionStorage
 */

var save = function save() {
  set(storageKey, store.state);
};

var store = {
  state: {
    formType: 'withCard',
    verificationType: 'clientNr',
    dataSubmitted: false,
    signedIn: false,
    clientEmail: '',
    counterNr: '',
    internetCode: '',
    // Override JSON.stringify()
    toJSON: function toJSON() {
      var obj = {};

      for (var p in this) {
        if (Object.prototype.hasOwnProperty.call(this, p)) {
          if (exceptions.indexOf(p) < 0) {
            obj[p] = this[p];
          }
        }
      }

      return obj;
    }
  },
  setClientEmail: function setClientEmail(email) {
    this.state.clientEmail = email;
    save();
  },
  setCounterNr: function setCounterNr(counterNr) {
    this.state.counterNr = counterNr;
    save();
  },
  setInternetCode: function setInternetCode(internetCode) {
    this.state.internetCode = internetCode;
    save();
  },
  updateFormType: function updateFormType(type) {
    this.state.formType = type;
    save();
  },
  updateVerificationType: function updateVerificationType(vt) {
    this.state.verificationType = vt;
    save();
  },
  setDataSubmitted: function setDataSubmitted(isSubmitted) {
    this.state.dataSubmitted = isSubmitted;
    save();
  },
  setSignedIn: function setSignedIn(signedIn) {
    this.state.signedIn = signedIn;
    save();
  },
  reset: function reset() {
    this.state.formType = 'withCard';
    this.state.verificationType = 'clientNr';
    this.state.dataSubmitted = false;
    save();
  }
};

var initState = function initState() {
  var state = get(storageKey, false);

  if (state) {
    if (state.dataSubmitted === true) {
      store.reset();
    } else {
      store.state = merge(store.state, state);
    }
  }
};

export { store, initState };