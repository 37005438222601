//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    modelValue: {
      default: ''
    }
  },
  computed: {
    checked: function checked() {
      return this.modelValue === this.value;
    }
  },
  methods: {
    updateInput: function updateInput() {
      this.$emit('change', this.value);
    }
  }
};