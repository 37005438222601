var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.href)?_c('button',{class:[
      'button',
      {'button--secondary': _vm.secondary},
      {'button--is-loading': _vm.showLoader}
    ],on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('span',{staticClass:"button__label"},[_vm._t("default")],2),_vm._v(" "),(_vm.showLoader)?_c('span',{staticClass:"button__loader"},[_c('span',{staticClass:"button__loader-anim"})]):_vm._e()]):(_vm.href)?_c('a',{class:[
  'button',
  {'button--secondary': _vm.secondary},
  {'button--is-loading': _vm.showLoader}
],attrs:{"href":_vm.href,"target":"_blank"}},[_c('span',{staticClass:"button__label"},[_vm._t("default")],2),_vm._v(" "),(_vm.showLoader)?_c('span',{staticClass:"button__loader"},[_c('span',{staticClass:"button__loader-anim"})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }