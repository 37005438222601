//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PageTitle from 'components/content/page-title/page-title.vue';
import ReadingForm from 'components/content/reading-form/reading-form.vue';
import Loader from 'components/base/loader/loader.vue';
import { store } from 'js/modules/store';
export default {
  components: {
    ReadingForm: ReadingForm,
    Loader: Loader,
    PageTitle: PageTitle
  },
  data: function data() {
    return {
      sharedState: store.state
    };
  }
};