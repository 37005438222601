//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PageTitle from 'components/content/page-title/page-title.vue';
import Btn from 'components/base/button/button.vue';
export default {
  components: {
    Btn: Btn,
    PageTitle: PageTitle
  }
};