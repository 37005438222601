export default {
  error: function error() {
    return '';
  },
  required: function required() {
    return 'Bitte füllen Sie das Pflichtfeld aus.';
  },
  maxLength: function maxLength(max) {
    return "Maximal ".concat(max, " Zeichen sind erlaubt.");
  },
  minLength: function minLength(min) {
    return "Mindestens ".concat(min, " Zeichen eingeben.");
  },
  email: function email() {
    return 'Bitte geben Sie eine gültige E-mail ein.';
  },
  numeric: function numeric() {
    return 'Nur Zahlen sind erlaubt.';
  },
  readingNotValid: function readingNotValid() {
    return 'Bitte überprüfen Sie nochmal den Zählerstand.';
  },
  counterNrInvalid: function counterNrInvalid() {
    return 'Bitte überprüfen Sie die Zählernummer.';
  },
  internetCodeInvalid: function internetCodeInvalid() {
    return 'Bitte überprüfen Sie den Internetcode.';
  }
};