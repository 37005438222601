/**
 * sessionStorage helper
 *
 * */
// Private functions
var deserialize = function deserialize(value) {
  return typeof value === 'string' ? JSON.parse(value) : undefined;
};

var serialize = function serialize(value) {
  return JSON.stringify(value);
}; // Public functions


export var get = function get(key, defaultValue) {
  var value = deserialize(window.sessionStorage.getItem(key));
  return typeof value === 'undefined' ? defaultValue : value;
};
export var set = function set(key, value) {
  window.sessionStorage.setItem(key, serialize(value));
};