export default {
  FIREBASE_API_KEY: 'AIzaSyBybzSuWq8DKNIDVuIHHQkkE0JKCwYCz98',
  FIREBASE_AUTH_DOMAIN: 'e360-meter-reading.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://e360-meter-reading.firebaseio.com',
  FIREBASE_PROJECT_ID: 'e360-meter-reading',
  FIREBASE_STORAGE_BUCKET: 'e360-meter-reading.appspot.com',
  FIREBASE_MESSAGIN_SENDER_ID: '114516152683',
  FIREBASE_APP_ID: '1:114516152683:web:b55d954cbaeb2c1bf53c2e',
  APP_USER_EMAIL: 'webapp@energie360.ch',
  APP_USER_PASSWORD: 'energie360'
};