//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PageTitle from 'components/content/page-title/page-title.vue';
import InputField from 'components/base/input/input.vue';
import Btn from 'components/base/button/button.vue';
import Panel from 'components/content/panel/panel.vue';
import { required, email } from 'vuelidate/lib/validators';
import errorMessages from 'js/util/error-messages';
import { writeDocument } from 'js/modules/db-handler';
import config from 'js/config';
export default {
  components: {
    PageTitle: PageTitle,
    InputField: InputField,
    Btn: Btn,
    Panel: Panel
  },
  data: function data() {
    return {
      formName: '',
      formNameError: '',
      formEmail: '',
      formEmailError: '',
      formPhone: '',
      formPhoneError: '',
      formMessage: '',
      submitting: false,
      formSubmitted: false
    };
  },
  methods: {
    validate: function validate(modelName) {
      // TODO: duplicate logic. put this in a module or something.
      this.$v.$touch();
      var validationModel = this.$v[modelName];
      var errModelName = modelName + 'Error';

      if (validationModel.$anyError) {
        for (var p in validationModel.$params) {
          if (Object.prototype.hasOwnProperty.call(validationModel.$params, p)) {
            if (!validationModel[p]) {
              switch (p) {
                case 'required':
                  this[errModelName] = errorMessages[p]();
                  break;

                case 'email':
                  this[errModelName] = errorMessages[p]();
                  break;

                case 'minLength':
                  this[errModelName] = errorMessages[p](validationModel.$params[p].min);
                  break;

                case 'maxLength':
                  this[errModelName] = errorMessages[p](validationModel.$params[p].max);
                  break;

                case 'numeric':
                  this[errModelName] = errorMessages[p]();
                  break;

                default:
                  this[errModelName] = errorMessages.error();
              }
            }
          }
        }
      } else {
        this[errModelName] = '';
      }
    },
    submit: function submit() {
      this.$v.$touch(); // Show or clear errors

      for (var p in this.$v.$params) {
        if (Object.prototype.hasOwnProperty.call(this.$v.$params, p)) {
          this.validate(p);
        }
      }

      if (!this.$v.$anyError) {
        this.postData();
      }
    },
    postData: function postData() {
      var _this = this;

      // TODO: duplicate logic. put this in a module or something.
      this.submitting = true; // Check honeypot field

      if (this.$refs.hpField.value !== '') {
        // We have a robot!
        // Don't send anything.
        // But we still show the confirmation page.
        this.submitting = false;
        this.formSubmitted = true;
        return;
      }

      writeDocument(config.pelletsMailCollection, {
        name: this.formName,
        email: this.formEmail,
        phone: this.formPhone,
        message: this.formMessage
      }).then(function () {
        console.log('Pellets request sent!');
        _this.submitting = false;
        _this.formSubmitted = true;
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 40);
      });
    }
  },
  validations: {
    formName: {
      required: required
    },
    formEmail: {
      required: required,
      email: email
    },
    formPhone: {
      required: required
    }
  }
};