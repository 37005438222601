//
//
//
//
//
//
//
//
import SvgIcon from 'components/base/svg/svg.vue';
export default {
  components: {
    SvgIcon: SvgIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  }
};