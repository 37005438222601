//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    inputListeners: function inputListeners() {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        input: function input(e) {
          vm.$emit('input', e.target.value);
        }
      });
    }
  },
  data: function data() {
    return {
      hasFocus: false,
      isEmpty: true
    };
  },
  mounted: function mounted() {
    this.onBlur();
  },
  methods: {
    onFocus: function onFocus() {
      this.hasFocus = true;
    },
    onBlur: function onBlur() {
      this.hasFocus = false;
      this.isEmpty = this.$refs.input.value === '';
    }
  }
};